import React from 'react'
import { graphql } from 'gatsby'
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Hero from '../../components/heroSubpage'

const Ethics = ({ data }) => {
  const post = data.markdownRemark
  return (
    <div id="sub-page">
      <Layout>
        <Hero
          class="intro-65"
          image={post.frontmatter.image.childImageSharp.gatsbyImageData}
          foreimage={null}
          forealt={post.frontmatter.imageAltText}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          type="root"
          gradientClass="mask d-flex justify-content-center align-items-center gradient"
        />

        <main>
          <section className="bg-white">
            <MDBContainer>
              <MDBRow>
                <MDBCol>
                <div className="mt-3 richtext divlink" dangerouslySetInnerHTML={{ __html: post.html }} />
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </section>
        </main>
      </Layout>
    </div>
  )
}
export default Ethics

export function Head({ data, location }) {
  const post = data.markdownRemark
  return (
    <SEO
      title={post.frontmatter.meta.title}
      description={post.frontmatter.meta.description}
      url={location.pathname} 
      image={post.frontmatter.image.childImageSharp?.gatsbyImageData?.images?.fallback?.src}          
      canonical={location.pathname}                  
    />
  )
}

export const query = graphql`
  query {     
    markdownRemark(frontmatter: { name: { eq: "ethics-program" } }) {
      frontmatter {
        meta {
          title
          description 
        }
        title
        subtitle
        alttext
        image {
          childImageSharp {
            gatsbyImageData(quality: 90) 
          }
        }
      }
      html
    }
  }
`